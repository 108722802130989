import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import OrderSuccess from '@lp-root/src/modules/order-success/order-success.component';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, Button, Hbox, Separator } from '@web/atomic';
import WhatsappFAB from '@web/atomic/atm.whatsapp-fab/whatsapp-fab.component';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { NativeHelper } from '@web/data/native.helper';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';

const CompraRealizadaAppPage: React.FunctionComponent<PageProps> = (props) => {
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();

  const handleContinueClick = () => {
    NativeHelper.postMessage({ type: 'has_subscribed' });
    close();
  };

  const socialMedia = {
    canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
    title: 'Sucesso! Estamos processando seu pedido!',
    description: 'Assim que o pagamento for identificado, o programa completo será disponibilizado para você.',
  };
  return (
    <>
      <SEO socialMedia={socialMedia} />
      <OrderSuccess headline={'Sucesso 🎉! Estamos processando seu pedido!'} hideDownload={true}>
        <Body>
          Assim que o pagamento for identificado no nosso sistema, iremos liberar todo o programa automaticamente para você! <br />
          Mas se tiver qualquer problema/dúvida, fique à vontade para entrar em contato conosco.
        </Body>
        <Separator />
        <Hbox hAlign="center">
          <Button onClick={handleContinueClick} loading={waitCloseLoading}>
            Continuar
          </Button>
        </Hbox>
      </OrderSuccess>
      <WhatsappFAB kind="support" />
    </>
  );
};

export default CompraRealizadaAppPage;

export const query = graphql`
  query CompraRealizadaAppQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
